
import * as Li9za2lrby5tanM from './skiko.mjs';
import * as _ref_QGpzLWpvZGEvY29yZQ_ from '@js-joda/core';
import * as _ref_QGpzLWpvZGEvdGltZXpvbmU_ from '@js-joda/timezone';
import * as _ref_ZmlyZWJhc2UvYXBw_ from 'firebase/app';
import * as _ref_ZmlyZWJhc2UvYXV0aA_ from 'firebase/auth';
import { instantiate } from './composeApp.uninstantiated.mjs';

const exports = (await instantiate({
    './skiko.mjs': Li9za2lrby5tanM,
    '@js-joda/core': _ref_QGpzLWpvZGEvY29yZQ_,
    '@js-joda/timezone': _ref_QGpzLWpvZGEvdGltZXpvbmU_,
    'firebase/app': _ref_ZmlyZWJhc2UvYXBw_,
    'firebase/auth': _ref_ZmlyZWJhc2UvYXV0aA_
})).exports;

export default new Proxy(exports, {
    _shownError: false,
    get(target, prop) {
        if (!this._shownError) {
            this._shownError = true;
            throw new Error("Do not use default import. Use the corresponding named import instead.")
        }
    }
});
export const {
    main,
    _initialize,
    memory
} = exports;

